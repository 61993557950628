import api from "./api";

export const postConvertPdf = async (file: any) => {
    const response = await api.post(`uploads/convert-docx-to-pdf-office/`, file, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob"
    });
    return response;
};

export const postConvertPdfV3 = async (file: any) => {
    const response = await api.post(`uploads/convert-docx-to-pdf-office-v3/`, file, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob"
    });
    return response;
  };