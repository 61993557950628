/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
//import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
//import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import { useToast } from '../../../pages/components/Toats'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useThemeMode } from '../../../../_metronic/partials'


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const { toggleToast } = useToast();
  const {mode} = useThemeMode()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
        //document.documentElement.style.setProperty('--primary-color', '#212e48');

        window.location.reload();
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Usuário ou senha incorretos')
        toggleToast("Usuário ou senha incorretos", "error")
        setSubmitting(false)
        setLoading(false)
      }
    },
  })


  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        {/* <h1 className='text-dark fw-bolder mb-3'>Login</h1> */}
        <Link to='/' className='mb-5'>
            {mode === 'dark' ? 
              <img alt='Logo' src={toAbsoluteUrl('/media/imgs/novalogo.png')} className='h-60px' />
              : <img alt='Logo' src={toAbsoluteUrl('/media/imgs/novaLogoBlack.png')} className='h-60px' />
            }
        </Link>
        <div className='text-gray-500 fw-semibold fs-6'>Monitor de Usinas Solares</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      {/* <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
        </div>

        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div>
      </div> */}

      {/* begin::Separator */}
      
      {/* <div className='separator separator-content my-14 text-primary' style={{padding: '1rem'}}>
        <span className='w-400px text-gray-500 fw-semibold fs-7'>Informe seus dados de acesso</span>
      </div> */}

      {/* end::Separator */}

      {formik.status ? (
        <div className='mb-10 alert alert-danger'>
          <div className='alert-text font-weight-bold text-center'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-primary p-2 rounded border border-dashed border-primary'>
          <div className='text-primary fs-7 text-center'>
            Informe seus <strong>dados de acesso</strong>
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
        <input
          placeholder='E-mail'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
        <input
          type='password'
          placeholder='Senha'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Esqueceu a senha ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-9'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-dark'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Entrar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Entrando ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}

      <div 
        className='text-gray-500 text-center fw-semibold fs-6 mb-5' 
        style={{display: 'flex', alignItems: 'center',justifyContent: 'center', gap: '0.5rem'
      }}>
        <a className='text-gray-500 fw-semibold fs-6' href="https://solarvision.app/privacy-policy/" target='_blank' rel="noreferrer">Privacidade</a>
        <span>|</span>
        <a className='text-gray-500 fw-semibold fs-6' href="https://solarvision.app/termos-de-uso/" target='_blank' rel="noreferrer">Termos de Uso</a>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6 mb-5'>
        Siga nossas Redes Sociais
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-4'>
          <a
            href='https://www.instagram.com/solarvision.app/'
            target='_blank'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/instagram-2-1.svg')}
              className='h-15px me-3'
            />
            Instagram
          </a>
        </div>

        <div className='col-md-4'>
          <a
            href='https://www.facebook.com/photo/?fbid=1011853887622446&set=pb.100063935325178.-2207520000'
            target='_blank'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
              className='theme-light-show h-15px me-3'
            />
            Facebook
            {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            /> */}
          </a>
        </div>

       {<div className='col-md-4'>
          <a
            href='https://www.youtube.com/@solarvisioapp'
            target='_blank'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/youtube-play.svg')}
              className='theme-light-show h-15px me-3'
            />
            Youtube

          </a>
        </div>}

      </div>
    </form>
  )
}
