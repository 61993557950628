import React from 'react';

type Props = {
    className: string;
    myDate: any;
    setMySearch: any;
    control: any;
    setControl: any;
    searchParameter: string
    placeholder: string
    style?: any;
    disabled?: boolean
}

export function SearchInput({ className, myDate, setMySearch, control, setControl, searchParameter, placeholder, style, disabled }: Props) {

    function getNestedPropertyValue(obj: any, path: string): any {
        const keys = path.split('.');
        return keys.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : undefined, obj);
    }


    function handleChange(evt: any) {
        const inputValue = evt.target.value.toLowerCase();

        if (inputValue !== "") {
            if (myDate) {
                let search: any = [];
                for (let i = 0; i < myDate.length; i++) {
                    const nestedPropertyValue = getNestedPropertyValue(myDate[i], searchParameter);
                    if (nestedPropertyValue !== null && nestedPropertyValue.toLowerCase().indexOf(inputValue) >= 0) {
                        search.push(myDate[i]);
                    }
                }
                setMySearch(search);
                if (control === false) {
                    setControl((prev: any) => !prev);
                }
            }
        } else {
            setMySearch([]);
            if (control === true) {
                setControl((prev: any) => !prev);
            }
        }
    }

    return (
        <input
            type='text'
            data-kt-user-table-filter='search'
            className={className}
            placeholder={placeholder}
            style={style}
            disabled={disabled ? disabled : false}
            onChange={handleChange}
        />
    );
}