/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";

import { getplantsAll } from "../../../../../../services/plantsService";
import { getUnitBranchAll } from "../../../../../../services/unitBranchService";
import { getUsersAllRole } from "../../../../../../services/usersService";
import { Loading } from "../../../../../components/Loadings/loading";
import { ListPlantsLinked } from "../listPlantsLinked";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { ModalAddUpdateUser } from "../../../../../users/components/ModalAddEditUser";
import useSize from "../../../../../../hooks/useSize";


const StepModal = ({data, hasError, formik}: any) => {
  const [plants, setPlants] = useState<any[]>([]);
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [loadingUnitBranch, setLoadingUnitBranch] = useState<boolean>(false);
  const [loadingPlants, setLoadingPlants] = useState<boolean>(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [myUser, setMyUser] = useState<any | null>(null);
  const {width: largura} = useSize();
  
  // const getUsersAllClients = async () => {
  //   try {
  //     setLoadingUser(true)
  //     const users = await getUsersAllRole("CLIENTE", formik.values.idUnitBranch ? formik.values.idUnitBranch : "")
  //     setUsers(users)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar a lista de usuarios. ", "error")
      
  //   }finally{
  //     setLoadingUser(false)
  //   }
  // }

  const getUsersAllUnitBranchs = async () => {
    try {
      setLoadingUnitBranch(true)
      const myUnitBranchs = await getUnitBranchAll()
      setUnitBranchs(myUnitBranchs)

      console.log('unitBranchs', myUnitBranchs)
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoadingUnitBranch(false)
    }
  }

  const getPlants = async () => {
    try {
      setLoadingPlants(true)
      let plants = await getplantsAll(`?unitBranch=${formik.values.idUnitBranch ? formik.values.idUnitBranch : ""}`)
      plants = plants.map((plant: any) => {
        const exist = formik.values.idsPlants.some((id: string) => id === plant.id)
        plant.imported = exist ? true : false
        return plant
      })
      plants = plants.sort((a: any, b: any) => (b.imported ? 1 : -1))
      
      setPlants(plants)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoadingPlants(false)
    }
  }

  const refresh = () => {
    getPlants()
    //getUsersAllClients()
  }

  useEffect(() => {
    //getUsersAllClients()
    getUsersAllUnitBranchs()
    getPlants()
  }, []);

  useEffect(() => {
    getPlants()
    //getUsersAllClients()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.idUnitBranch]);

  if (loadingUser || loadingUnitBranch || loadingPlants) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }

  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100'>
        <div style={{display: 'flex', gap: '1rem'}}>
          
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Name</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='name'
              placeholder=''
              value={formik.values.name}
              onChange={formik.handleChange("name")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um nome para o collaborador
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>E-mail</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um e-mail para unidade(filial)'
              ></i>
            </label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              name='email'
              placeholder='name@example.com'
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              
            />
            {!formik.values.emailCompany && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  O e-mail é obrigatório!
                </div>
              </div>
            )}
          </div>
        </div>

        <div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>CPF</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite o CPF da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='cpf'
              placeholder='000.000.000-00'
              value={formik.values.cpf}
              onChange={formik.handleChange("cpf")}
            />
            {!formik.values.cnpj && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  o CPF é obrigatório!
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>Telefone</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Digite um telefone para a cliente'
            ></i>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='telephone'
            placeholder='0000-0000'
            value={formik.values.telephone}
            onChange={formik.handleChange("telephone")}
          />
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: largura > 1200 ? 'row' : 'column', gap: '1.5rem'}}>
          {largura < 1200 && (<ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <a
                className="nav-link active text-active-primary me-6"
                data-bs-toggle="tab"
                href="#plant"
              >
                Outras informações
              </a>
            </li>
            <li className='nav-item'>
              <a
                className="nav-link text-active-primary me-6"
                data-bs-toggle="tab"
                href="#user"
              >
                Usinas vinculadas
              </a>
            </li>
          </ul>)}
          
          <div 
            style={{display: largura > 1200 ? 'flex' : '', width: '100%'}} 
            className={largura < 1200 ? "tab-content" : ""} 
            id="myTabContent5"
          >
            <div 
              style={{flex: '2'}} 
              className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3 tab-pane fade active show'
              id="plant"
              role="tabpanel"
            >
              <div style={{marginBottom: '2rem'}}>
                <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                    <span className='card-label fw-bold fs-3 mb-1'>Unidade (filial) {" "}</span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Filial vinculada ao cliente</span>
                </h3>
              </div>

              <div>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Lista de Unidades (Filiais)</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Selecione para qual unidade filial essa credencial será vinculada. '
                    ></i>
                  </label>
                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                      onChange={formik.handleChange("idUnitBranch")}
                    >
                      <option value={'1'}></option>
                      {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                        return (
                          <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                        )
                      })}
                      
                    </select>
                    {!data.idUnitBranch && hasError && (
                    <div className='fv-plugins-message-container'>
                      <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        Selecione a unidade!
                      </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>

              <div style={{marginBottom: '2rem', marginTop: '3rem'}}>
                <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                    <span className='card-label fw-bold fs-3 mb-1'>
                      Usuarios {" "}
                      <button
                        className='btn btn-sm text-hover-primary'
                        disabled
                        style={{padding: '0rem'}}
                        onClick={(event) => {
                            setShowCreateAppModal(true)
                            //event.stopPropagation();
                            //setShowModalDescriptionOS(true)
                        }}
                        type="button"
                      >
                        <KTSVG className='svg-icon-muted svg-icon-2x' path='/media/icons/duotune/general/gen035.svg' />
                      </button>
                    </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Usuário vinculado ao Collaborador</span>
                </h3>
              </div>

              <div>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span>Lista de Usuarios</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Selecione para qual unidade filial essa credencial será vinculada. '
                    ></i>
                  </label>
                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      disabled
                      defaultValue={formik.values.idUser ? formik.values.idUser : '1'}
                      onChange={formik.handleChange("idUser")}
                    >
                      <option value={'1'}></option>
                      {users && users.map((myUsers: any) => {
                        return (
                          <option key={myUsers.id} value={myUsers.id}>{myUsers.name}</option>
                        )
                      })}      
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div 
              style={{flex: '3'}}
              className={largura < 1200 ? "tab-pane fade show divTableReport" : ""}
              id="user"
              role="tabpanel"
            >
              <ListPlantsLinked plants={plants} className="className='card-xl-stretch bg-body mb-xl-8" formik={formik}/>
            </div>
          </div>
          
        </div>

        {/*end::Form Group */}

        {/*begin::Form Group */}
        {/*end::Form Group */}
      </div>
      <ModalAddUpdateUser
          show={showCreateAppModal}
          id={myUser ? myUser.id : undefined} 
          data={myUser ? myUser : undefined}
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModal(false)
            //refresh()
          }} 
        />
    </div>
  )
}

export {StepModal}
function toggleToast(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}

