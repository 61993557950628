import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { formatDate } from '../../../utils/date';
import { BarGraph } from './Componentes/BarGraphModal';
import { Loading } from '../../components/Loadings/loading';
import { ModalPlant } from '../../components/modalPlant';
import { ModalResumoReport } from '../../reports/components/ModalResumoReport';
import useSize from '../../../hooks/useSize';
import { Plants, Prognosis } from '../../../@types/plants';
import { Report } from '../../../@types/report';
import { SelectGlobal } from '../../components/SelectGlobal';

import styles from './graphicsModalPlant.module.css';

type Props = {
  charts: any;
  loading: boolean;
  typeGraphc?: string;
  plantsFilter?: Plants[];
  dataFilter?: string | null;
  data?: Plants | undefined;
  prognostico?: number | null;
  loadingReport: boolean;
  report: Report | null;
  myProg?: Prognosis | null;
  setDataFilter?: (plant: string) => void;
  refresh: () => Promise<void>;
}

export function GraphicsDahsboardPlant({
  charts, 
  refresh, 
  loading, 
  typeGraphc, 
  plantsFilter, 
  dataFilter, 
  setDataFilter, 
  data, prognostico, 
  myProg,
  report}: Props) {

  const [weekCharts, setWeekCharts] = useState({
    '2024-06-13' : {
      dias: [],
      valores: [],
      type: "week_charts"
    }
  });
  const {height: altura, width: largura} = useSize();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false);

  const toFillCharts = () => {
    if(charts){
      setWeekCharts(charts && charts.week_charts ? charts.week_charts : null)
    }
  }

  const closedModal = (active: boolean) => {
    refresh()
    setShowCreateAppModal(active)
  }
  
  useEffect(() => {
    toFillCharts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charts]);

  if (loading) {
    return (
      <div 
        className={`row g-xl-8 card p-0 ${styles.divLoadingCharts}`}>
          <Loading myClass={`spinner-border ${styles.loadingChart}`} />
      </div>)
  }else{
    if(!charts){
      return (
        <div 
          className={`row g-xl-8 card p-0 ${styles.divNotCharts}`} 
          style={{
            height: altura <= 750 ? '35vh' 
          : altura <= 850 ? '42vh'
          : altura <= 930 ? '45vh'
          : '50vh',
          }}
        >
          <h3 className={styles.h3DivNotChart}>
            <span className={`text-muted mt-1 fw-semibold fs-5 ${styles.spanNotGraphics}`}>Não há dados de graficos.</span>
          </h3>
        </div>
      )
    }
  }

  return (
    <div
      className={`row p-${largura < 1200 ? '0': '6'} card ${styles.divGlobal}`} 
      style={{
        width: largura < 1200 || (typeGraphc && typeGraphc === 'grapich') ? '100%' : '60%',
      }}
    >
      <div className={styles.divGlobalV2}>
        <div className={`d-flex align-items-center ${styles.divGlobalV3}`}>
          <div className={`d-flex align-items-center ${styles.divGlobalV4}`}>
            <div className={`symbol symbol-${largura < 1200 ? '40px' : '50px'} me-2`}>
              <img
                src={toAbsoluteUrl('/media/imgs/plant2.png')}
                className=''
                alt=''
              />
            </div>
            <h3 className={`card-title align-items-start flex-column ${styles.h3Status}`}>
              <span className={`card-label fw-bold fs-${largura < 1200 ? '7' : '4'} mb-1`}>{data && data.name ? data.name : "Não identificado."}</span>  
              <span 
                className={
                  `badge badge-light-${
                    data && data.status !== null
                      ? data.status === 0
                      ? "success"
                      : data.status === 1
                      ? "danger"
                      : data.status === 2
                      ? "warning"
                      : data.status === 7 || data.status === 3
                      ? "info"
                      : "secondary"
                    : "secondary"
                  } fs-8 fw-semibold`
                }
              >
                {
                  data && data.status !== null
                    ? data.status === 0
                    ? "Online"
                    : data.status === 1
                    ? "Offline"
                    : data.status === 2
                    ? "Alerta"
                    : data.status === 7 || data.status === 3
                    ? "Lembrar"
                    : "Desconhecido"
                : "Desconhecido"
                }
              </span>
            </h3>
          </div>
          <div className={styles.divClassFilter}>
            {largura < 1200 ?
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-white form-select-sm'
                value={dataFilter ? dataFilter : "1"}
                onChange={(event: any) => {
                  setDataFilter ? setDataFilter(event.target.value) : console.log('Sem função de filtro')
                }}
              >
                <option value="1" className='text-muted' disabled>Selecione uma usina</option>
                {
                  plantsFilter && plantsFilter.length > 0 && plantsFilter.map((plant: any) => {
                    return (
                      <option value={plant.id}>{`${plant.name}`}</option>
                    );
                  })
                }
              </select>
            :
              <SelectGlobal 
                itens={plantsFilter}
                buttonAll={false}
                textSearch={"Buscar Usina"}
                labelKey='name'
                valueKey='id'
                value={dataFilter ? dataFilter : ""}
                onChange={(value: string) => {
                  setDataFilter ? setDataFilter(value) : console.log('Sem função de filtro')
                }}
              />}
          </div>
        </div>
      </div>

      <div
        className={styles.divGraphics} 
        style={{
          height: altura <= 750 ? '35vh' 
          : altura <= 850 ? '42vh'
          : altura <= 930 ? '45vh'
          : '50vh',
        }}
      >
        <div className={styles.divGraphicsV2}>
          <div>
            <div>
              <BarGraph 
                myProg={myProg} 
                largura={largura} 
                className='' 
                charts={weekCharts} 
                setShowCreateAppModal={setShowCreateAppModal} 
                prognostico={prognostico ? prognostico : null}
              />
            </div>
          </div>
        </div>

        {typeGraphc && typeGraphc === 'grapich' ? 
          <div className={styles.divSubInformations}>
            <div className={styles.divReportGraphics}>
              <h3 className='card-title align-items-start flex-column'>
                <span className='text-muted fw-semibold fs-7'>
                  Ciclo de <span className='fw-bold'>{formatDate(new Date())}</span>
                </span>
              </h3>
              <button
                type='button'
                disabled={report ? false : true}
                className={`btn btn-sm btn-primary ${styles.buttonReport}`}
                onClick={() => {
                  setShowCreateAppModalDetails(true)
                }}
                >
                  <span className='fs-7'>Ver Relatório</span>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-4 ms-2 me-0'
                  />     
              </button>
            </div>

            <div id="carouselExampleControlsV3" className={`carousel carousel-dark slide ${styles.divCarousel}`} data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className={`carousel-item active ${styles.divCarouselV2}`}>
                  <div className={styles.divCarouselV3}>
                    <div className={styles.h3ItemCarouselV3}>
                      <KTSVG path={'/media/icons/duotune/general/gen060.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
                      <h3 className={`card-title align-items-start flex-column ${styles.itemH3Carousel}`}>
                        <span className={`text-muted fw-semibold fs-9 ${styles.spanItemCarousel}`}>Gerado</span>
                        <span className={`card-label fw-bold fs-7 mb-1 ${styles.spanItemCarousel}`}>{data && data.energyMonth ? data.energyMonth : 0} KWh</span>
                      </h3>
                    </div>

                    <div className={styles.h3ItemCarouselV3}>
                      <KTSVG path={'/media/icons/duotune/finance/fin003.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
                      <h3 className={`card-title align-items-start flex-column ${styles.itemH3Carousel}`}>
                        <span className={`text-muted fw-semibold fs-9 ${styles.spanItemCarousel}`}>Faturado</span>
                        <span className={`card-label fw-bold fs-7 mb-1 ${styles.spanItemCarousel}`}>R$ {data && data.value_fat_mes ? data.value_fat_mes : 0}</span>
                      </h3>
                    </div>

                    <div className={styles.h3ItemCarouselV3}>
                      <KTSVG path={'/media/icons/duotune/finance/fin008.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
                      <h3 className={`card-title align-items-start flex-column ${styles.itemH3Carousel}`}>
                        <span className={`text-muted fw-semibold fs-9 ${styles.spanItemCarousel}`}>Economizado</span>
                        <span className={`card-label fw-bold fs-7 mb-1 ${styles.spanItemCarousel}`}>R$ {data && data.economy_mes ? data.economy_mes : 0}</span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className={`carousel-item ${styles.divCarouselV2}`}>
                  <div className={styles.divCarouselV3}>
                    <div className={styles.h3ItemCarouselV3}>
                      <img src={toAbsoluteUrl('/media/imgs/co2.png')} className={styles.imgItemCarousel} alt='Metornic' />
                      <h3 className={`card-title align-items-start flex-column ${styles.itemH3Carousel}`}>
                        <span className={`text-muted fw-semibold fs-9 ${styles.spanItemCarousel}`}>CO2</span>
                        <span className={`card-label fw-bold fs-7 mb-1 ${styles.spanItemCarousel}`}>{data && data.co2 ? data.co2 : 0} ton</span>
                      </h3>
                    </div>

                    <div className={styles.h3ItemCarouselV3}>
                      <img src={toAbsoluteUrl('/media/imgs/so2.png')} className={styles.imgItemCarousel} alt='Metornic' />
                      <h3 className={`card-title align-items-start flex-column ${styles.itemH3Carousel}`}>
                        <span className={`text-muted fw-semibold fs-9 ${styles.spanItemCarousel}`}>SO2</span>
                        <span className={`card-label fw-bold fs-7 mb-1 ${styles.spanItemCarousel}`}>R$ {data && data.so2 ? data.so2 : 0} ton</span>
                      </h3>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <img src={toAbsoluteUrl('/media/imgs/coal.png')} className={styles.imgItemCarousel} alt='Metornic' />
                      <h3 className={`card-title align-items-start flex-column ${styles.itemH3Carousel}`}>
                        <span className={`text-muted fw-semibold fs-9 ${styles.spanItemCarousel}`}>Coal</span>
                        <span className={`card-label fw-bold fs-7 mb-1 ${styles.spanItemCarousel}`}>R$ {data && data.coal ? data.coal : 0} ton</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <button className={`carousel-control-prev ${styles.buttonCarousel}`} type="button" data-bs-target="#carouselExampleControlsV3" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className={`carousel-control-next ${styles.buttonCarousel}`} type="button" data-bs-target="#carouselExampleControlsV3" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        :
          <></>  
        }
      </div>

      <ModalPlant show={showCreateAppModal} handleClose={() => closedModal(false)} plant={dataFilter ? dataFilter : ""}/>
      <ModalResumoReport
        show={showCreateAppModalDetails}
        id={report && report.id ? report.id : undefined} 
        handleClose={() => {
          setShowCreateAppModalDetails(false)
        }}  
      />
    </div>
  );
}