import React, { createContext, useContext, useState, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';

interface ToastContextType {
  showToast: boolean;
  toggleToast: (message?: string, type?: string) => void;
  closeToast: () => void;
}

const ToastContext = createContext<ToastContextType>({
  showToast: false,
  toggleToast: () => {},
  closeToast: () => {},
});

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        closeToast();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const toggleToast = (message?: string, type?: string) => {
    setToastMessage(message || '');
    setToastType(type ? type : '');
    setShowToast(true);
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const truncateText = (text: string, maxLength = 50) =>
    text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <ToastContext.Provider value={{ showToast, toggleToast, closeToast }}>
      {children}
      {showToast && (
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 1055 }}
        >
          <Toast
            show={showToast}
            onClose={closeToast}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            className={`bg-${
              toastType === 'error'
                ? 'danger'
                : toastType === 'success'
                ? 'success'
                : toastType === 'alert'
                ? 'warning'
                : 'primary'
            }`}
            style={{
              width: '90vw',
              maxWidth: '40rem',
              minHeight: '4rem',
              display: 'flex',
              alignItems: 'center',
              zIndex: 1055,
            }}
          >
            <Toast.Body
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '1.2rem',
                alignItems: 'center',
                width: '100%',
                color: 'white',
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
                padding: '1rem',
              }}
            >
              {toastMessage.startsWith('http') ? (
                <a
                  href={toastMessage}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'white', textDecoration: 'underline' }}
                >
                  {truncateText(toastMessage, 50)}
                </a>
              ) : (
                toastMessage
              )}
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={closeToast}
                aria-label="Close"
              ></button>
            </Toast.Body>
          </Toast>
        </div>
      )}
    </ToastContext.Provider>
  );
};