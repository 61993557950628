/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../../../../_metronic/helpers';
import { putServiceOne } from '../../../../../../../../services/servicesService';
import { formatarData } from '../../../../../../../../utils/date';
import { linkPDF, linkPDFV2 } from '../../../../../../../../utils/savePDF';
import { useToast } from '../../../../../../../components/Toats';
import { useAuth } from '../../../../../../../../modules/auth';
import { ModalDetailsClient } from '../../../../../../../clients/components/ModalDetailsClient';
import { putProposalOne } from '../../../../../../../../services/proposalsService';
import { getFileBase64 } from '../../../../../../../../services/files';
import { getInvoicePDFFile } from '../../../../../../../../services/invoices';
import JSZip from 'jszip';
import { postConvertPdfV3 } from '../../../../../../../../services/ConvertPDFService';
import { getfileUrl } from '../../../../../../../../services/uploadService';
import useSize from '../../../../../../../../hooks/useSize';

type Props = {
  itemProposal: any;
  refresh: any
}

export function ItemProposalDetails({itemProposal, refresh}: Props) {
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [loadingUpdateProposal, setLoadingUpdateProposal] = useState<boolean>(false)
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false)
  const { toggleToast } = useToast();
  const {currentUser} = useAuth()
  const {width} = useSize();

  const getImage = async (key: string) => {
    try {
      const url = await getFileBase64(key)
      return url.base64

    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const replaceValues = async (dados: any, html: string) => {
    let novoHtml = html;
    const imageCompany = dados 
        && dados.unitBranch 
        && dados.unitBranch.company 
        && dados.unitBranch.company.image 
        ? await getImage(dados.unitBranch.company.image_dark)
        : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs";

    let dados_OS = {
        //imageCompany: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.image ? dados.unitBranch.company.image : "/media/imgs/sub-logo-v2.png",
        imageCompany: imageCompany ? `data:image/jpeg;base64,${imageCompany}` : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs",
        corporateName: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.corporateName ? dados.unitBranch.company.corporateName : "",
        cnpj: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.cnpj ? dados.unitBranch.company.cnpj : "",
        street: dados && dados.unitBranch && dados.unitBranch.street ? dados.unitBranch.street : "",
        district: dados && dados.unitBranch && dados.unitBranch.district ? dados.unitBranch.district : "",
        number: dados && dados.unitBranch && dados.unitBranch.number ? dados.unitBranch.number : "",
        telephone: dados && dados.unitBranch && dados.unitBranch.telephone ? dados.unitBranch.telephone : "",
        emailCompany: dados && dados.unitBranch && dados.unitBranch.emailCompany ? dados.unitBranch.emailCompany : "",
        number_order_service: dados && dados.number_order_service ? dados.number_order_service : "",
        dateCreate: dados && dados.created_at ? formatarData(dados.created_at) : "",
        nameResponsible: dados && dados.client && dados.client.nameResponsible ? dados.client.nameResponsible : "",
        cpfResponsible: dados && dados.client && dados.client.cpfResponsible ? dados.client.cpfResponsible : "",
        telephoneClient: dados && dados.client && dados.client.telephone ? dados.client.telephone : "0000-000",
        cellphoneClient: dados && dados.client && dados.client.cellphone ? dados.client.cellphone : "(00) 0 0000-0000",
        emailClient: dados && dados.client && dados.client.email ? dados.client.email : "",
        namePlant: dados && dados.plant && dados.plant.name ? dados.plant.name : "",
        addressPlant: dados && dados.plant && dados.plant.address ? dados.plant.address : "",
        titulo: dados && dados.title ? dados.title : "",
        status: dados && dados.status ? dados.status : "",
        description: dados && dados.description ? dados.description : "",
        companyName: dados && dados.unitBranch && dados.unitBranch.companyName ? dados.unitBranch.companyName : "",
        value_sub_final: dados && dados.value_sub_final ? dados.value_sub_final : "0",
        value_discont: dados && dados.value_discont ? dados.value_discont : "0",
        value_final: dados && dados.value_final ? dados.value_final : "0",
        itensProposal: dados && dados.itensProposal ? 
        dados.itensProposal.map((item: any) => {
            const my_item = {
                service: item ? 
                        item.tipo === "SERVICO" ? 
                            item.typeService && item.typeService.description ? item.typeService.description 
                        : "" 
                    : item.tipo === "PRODUTO" ? 
                        item.product && item.product.description ? item.product.description 
                        : "" 
                    : ""  
                : "",
                description: item && item.description ? item.description : "",
                value: item && item.value ? item.value : "",
                amount: item && item.amount ? item.amount : "",
                sub_value: item && item.sub_value ? item.sub_value : "",
            }
            return my_item
        }) : []

    }

    Object.keys(dados_OS).forEach(chave => {
        if(chave === 'itensProposal'){
            const servicesHtml = dados_OS[chave].map((service: any) => {
                const tds = Object.keys(service).map(key => {
                  
                    if(key === 'sub_value' || key === 'value'){
                        return `<td>R$ ${service[key]},00</td>`
                    }else{
                        return `<td>${service[key]}</td>`
                    }

                  //return `<td>${report[key]}</td>`;
                }).join('');
                return `<tr>${tds}</tr>`;
              }).join('');
    
              novoHtml = novoHtml.replace(
                `{{${chave}}}`, 
                `<table style="width: 100%;">
                    <thead>
                        <tr>
                            <th style="background: #e1e3ea;">Serviço/Produto</th>
                            <th style="background: #e1e3ea;">Descrição</th>
                            <th style="background: #e1e3ea;">Valor Unit.</th>
                            <th style="background: #e1e3ea;">Qtd</th>
                            <th style="background: #e1e3ea;">Total</th>
                        </tr>
                    </thead>
                    <tbody id="table_services">${servicesHtml}</tbody>
                </table>`
                // `<tbody id="table_services">${servicesHtml}</tbody>`
              );
        }else{
            const marcador = new RegExp(`{{${chave}}}`, 'g');
            novoHtml = novoHtml.replace(marcador, dados_OS[chave] );
        }
    });
    return novoHtml;
  }

  const updateProposalOne = async (id: any, status: any) => {
    try {
      setLoadingUpdateProposal(true)
      const body = {
        status: status
      }
      await putProposalOne(id, body)
      toggleToast("Sucesso ao atualizar a proposta. ", "success")
      //setClients(MyClient)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar a proposta. ", "error")
      
    }finally{
      setLoadingUpdateProposal(false)
      refresh()
    }
}

const processDocx = async (fileOrUrl: any, placeholders: any) => {
    try {
      let file;
  
      if (fileOrUrl && typeof fileOrUrl === "string") {

        const response: any = await getInvoicePDFFile(fileOrUrl);
          if (response) {
            const blob = response.data;
            file = new File([blob], "original-document.docx", { type: blob.type });
          }
      }
      
      if(file){
        const arrayBuffer = await file.arrayBuffer();
        const zip: any = await JSZip.loadAsync(arrayBuffer);
    
        const documentXml = await zip.file("word/document.xml").async("string");
        let updatedXml = documentXml;
    
        for (const [placeholder, value] of Object.entries(placeholders)) {
          const regex = new RegExp(`{{${placeholder}}}`, "g");
          updatedXml = updatedXml.replace(regex, value);
        }
  
        zip.file("word/document.xml", updatedXml);
        const updatedDocx = await zip.generateAsync({ type: "blob" });
    
        const pdfFormData = new FormData();
        pdfFormData.append("file", updatedDocx, "processed-document.docx");
    
        const response = await postConvertPdfV3(pdfFormData);
    
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    
        const uploadFormData = new FormData();
        uploadFormData.append("file", pdfBlob, "final-document.pdf");
    
        const uploadResponse = await getfileUrl(uploadFormData);

        if (uploadResponse && uploadResponse.url) {
          let pdfUrl = uploadResponse.url;
    
          if(width && width < 1000){
            const url = new URL(pdfUrl);
            url.searchParams.append('open_external_app', 'true');
            pdfUrl = url.toString();
          }
          window.open(pdfUrl, '_blank', 'noopener,noreferrer');
        } else {
          console.error('Erro ao obter a URL do arquivo após o upload.');
        }
        console.log("Documento processado e convertido para PDF com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao processar o documento:", error);
    }
};

const substituteValues = async (file: any, dados: any) => {
    if (file) {
        try {
        let placeholderData = {
            corporateName: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.corporateName ? dados.unitBranch.company.corporateName : "",
            cnpj: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.cnpj ? dados.unitBranch.company.cnpj : "",
            street: dados && dados.unitBranch && dados.unitBranch.street ? dados.unitBranch.street : "",
            district: dados && dados.unitBranch && dados.unitBranch.district ? dados.unitBranch.district : "",
            number: dados && dados.unitBranch && dados.unitBranch.number ? dados.unitBranch.number : "",
            telephone: dados && dados.unitBranch && dados.unitBranch.telephone ? dados.unitBranch.telephone : "",
            emailCompany: dados && dados.unitBranch && dados.unitBranch.emailCompany ? dados.unitBranch.emailCompany : "",
            number_order_service: dados && dados.number_order_service ? dados.number_order_service : "",
            dateCreate: dados && dados.created_at ? formatarData(dados.created_at) : "",
            nameResponsible: dados && dados.client && dados.client.nameResponsible ? dados.client.nameResponsible : "",
            cpfResponsible: dados && dados.client && dados.client.cpfResponsible ? dados.client.cpfResponsible : "",
            telephoneClient: dados && dados.client && dados.client.telephone ? dados.client.telephone : "0000-000",
            cellphoneClient: dados && dados.client && dados.client.cellphone ? dados.client.cellphone : "(00) 0 0000-0000",
            emailClient: dados && dados.client && dados.client.email ? dados.client.email : "",
            namePlant: dados && dados.plant && dados.plant.name ? dados.plant.name : "",
            addressPlant: dados && dados.plant && dados.plant.address ? dados.plant.address : "",
            titulo: dados && dados.title ? dados.title : "",
            status: dados && dados.status ? dados.status : "",
            description: dados && dados.description ? dados.description : "",
            companyName: dados && dados.unitBranch && dados.unitBranch.companyName ? dados.unitBranch.companyName : "",
            value_sub_final: dados && dados.value_sub_final ? dados.value_sub_final : "0",
            value_discont: dados && dados.value_discont ? dados.value_discont : "0",
            value_final: dados && dados.value_final ? dados.value_final : "0",
            ...(
                dados &&
                dados &&
                dados.itensProposal
                    ? dados.itensProposal.reduce((acc, item, index) => {
                        const idx = index + 1;
                        return {
                            ...acc,
                            [`itemDescription${idx}`]: item && item.description ? item.description : "",
                            [`itemService${idx}`]: item && item.tipo === "SERVICO" && item.typeService && item.typeService.description ? item.typeService.description : "",
                            [`itemProduct${idx}`]: item && item.tipo === "PRODUTO" && item.product && item.product.description ? item.product.description : "",
                            [`itemValue${idx}`]: item && item.value ? item.value : "",
                            [`itemAmount${idx}`]: item && item.amount ? item.amount : "",
                            [`itemSubValue${idx}`]: item && item.sub_value ? item.sub_value : "",
                        };
                    }, {})
                    : {}
            )
        };

        await processDocx(file, placeholderData);
        } catch (error) {
        console.error("Erro ao processar o documento:", error);
        }
    }
}

const fetchHtmlAsString = async () => {
    try {
      const response = await axios.get('/media/HTMLs/template_PRO.html', { responseType: 'text' });
      return response.data;
    } catch (error) {
      console.error('Error fetching HTML file:', error);
      return null;
    }
  };

// const previewPDF = async () => {
//     try {
//       setLoadingPDF(true)

//       const template = itemProposal 
//       && itemProposal.templatesProposals 
//       && itemProposal.templatesProposals.length > 0 
//       ? itemProposal.templatesProposals[0].textHTML 
//       : await fetchHtmlAsString();

//       const template_modificad = await replaceValues(itemProposal, template)
//       const myReturnLink = await linkPDF(template_modificad ? template_modificad : "<h1>Template não encontrado! </h1>")
//       window.open(myReturnLink, '_blank');
      
//       //setUrl(myReturnLink) 
    
//     } catch (error) {
//       console.log(error)  
//     }finally{
//       setLoadingPDF(false)
//     }
//   }

const previewPDF = async () => {
    try {
      const myTemplate = itemProposal 
      && itemProposal.templatesProposals 
      && itemProposal.templatesProposals.length > 0
      ? itemProposal.templatesProposals[0]
      : null

      if(myTemplate){
        if(myTemplate.typeDoc && myTemplate.typeDoc === 'html'){
          const templateHTML = itemProposal 
          && itemProposal.templatesProposals 
          && itemProposal.templatesProposals.length > 0 
          ? itemProposal.templatesProposals[0].textHTML 
          : await fetchHtmlAsString();

          const template_modificad = await replaceValues(itemProposal, templateHTML)
          await linkPDFV2(template_modificad ? template_modificad : "<h1>Template não encontrado! </h1>")
        }else{
          if(myTemplate.typeDoc && myTemplate.typeDoc === 'docx'){
            await substituteValues(myTemplate.linkDocx, itemProposal)
          }
        }
      }
    } catch (error) {
      console.log(error)  
    }
}


  return (
    <div className='card card-flush p-7'>
      <div className='card-title' style={{display: 'flex', justifyContent: 'space-between'}}>
          <h3 className='align-items-start flex-column' style={{display: "flex"}}>
              <span className='card-label fw-bold fs-4'>
                  {itemProposal && itemProposal.title ? itemProposal.title : "Sem Descrição"}
              </span>

          </h3>
          
          <div className='me-0 dropdown'>
              <button
                  //disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                  className='btn btn-lg text-hover-primary'
                  style={{padding: '0rem'}}
                  onClick={(event) => {
                      event.stopPropagation();
                  }}
                  //style={{width: largura < 1200 ? '100%' : '',display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}
                  type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"
              >
              <i className='bi bi-three-dots fs-1'></i>

              </button>

              <img src="" alt="" />
          
              <div className='dropdown-menu w-150px w-md-150px dropdown-menu-end' aria-labelledby="dropdownMenuButton2" style={{padding: '1rem'}}>
                  <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                      <button
                          className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                          }
                          style={{width: '100%'}}
                          data-bs-toggle="tab"
                          aria-disabled={loadingPDF}
                          onClick={()=> {
                            previewPDF();
                          }}
                      >
                          Imprimir
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <div className='card-body p-0' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                  <KTSVG path="/media/icons/duotune/communication/com006.svg" className="svg-icon-muted svg-icon-2hx"/>
                    <a href="#"
                        className='text-primary fw-bold text-hover-muted d-block fs-5'
                        onClick={()=> {
                            setShowCreateAppModalDetails(true)
                        }}
                    >
                        <h3>
                            <span className='text-muted text-hover-secondary fw-semibold fs-6'>
                                {itemProposal && itemProposal.client && itemProposal.client.nameResponsible ? itemProposal.client.nameResponsible : "Não identificado."}
                            </span>
                        </h3>
                    </a>
              </div>

              <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                        <KTSVG path="/media/icons/duotune/files/fil002.svg" className={`svg-icon-muted svg-icon-2hx`}/>
                        <div>
                            <h3 style={{marginBottom: '0rem', paddingBottom: '0rem'}}>
                                <span className={`text-dark fw-bold fs-6`}>
                                    Início: {" "}
                                </span>
                                <span className={`text-muted fw-semibold fs-6`}>
                                    {itemProposal && itemProposal.dateInit ? formatarData(itemProposal.dateInit) : "Sem data para iniciar o serviço."}
                                </span>
                            </h3>

                            <h3 style={{marginBottom: '0rem', paddingBottom: '0rem'}}>
                                <span className={`text-dark fw-bold fs-6`} >
                                    Fim: {" "}
                                </span>
                                <span className={`text-muted fw-semibold fs-6`}>
                                    {itemProposal && itemProposal.dateEnd ? formatarData(itemProposal.dateEnd) : "Sem data para finalizar o serviço."}
                                </span>
                            </h3>
                        </div>
              </div>

              <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                {/* <KTSVG path="/media/icons/duotune/files/fil002.svg" className="svg-icon-muted svg-icon-2hx"/> */}
                    <img src={toAbsoluteUrl("media/imgs/plant2.png")} height={35}/>
                  <h3>
                      <span className='text-muted fw-semibold fs-6'>
                          {itemProposal 
                          && itemProposal.plant 
                          && itemProposal.plant.name 
                          ? itemProposal.plant.name : "Usina do serviço não identificada."}
                      </span>
                  </h3>
              </div>

              <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                  <KTSVG path="/media/icons/duotune/ecommerce/ecm009.svg" className="svg-icon-muted svg-icon-2hx"/>
                  <h3>
                      <span className='text-muted fw-semibold fs-6'>
                        {itemProposal 
                            && itemProposal.unitBranch
                            && itemProposal.unitBranch.companyName
                            ? itemProposal.unitBranch.companyName : "Unidade resposável pelo serviço não identificado."}
                      </span>
                  </h3>
              </div>

              <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                  <KTSVG path="/media/icons/duotune/maps/map001.svg" className="svg-icon-muted svg-icon-2hx"/>
                      <div className='me-0 dropdown'>
                        <button
                            //disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                            className='btn btn-lg btn-light'
                            style={{padding: '0rem'}}
                            disabled={currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' ? false : true}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            //style={{width: largura < 1200 ? '100%' : '',display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}
                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                            //data-kt-menu-trigger='click'
                            //data-kt-menu-dismiss='false'
                            //data-kt-menu-placement='bottom-end'
                            //data-kt-menu-flip='top-end'
                        >
                            <span 
                            className={
                                `badge badge-light-${
                                    itemProposal && itemProposal.status && itemProposal.status !== null
                                    ? itemProposal.status === "EM NEGOCIACAO"
                                    ? "primary"
                                    : itemProposal.status === "NEGOCIO RECUSADO"
                                    ? "danger"
                                    : itemProposal.status === "NEGOCIO FECHADO"
                                    ? "success"
                                    : "secondary"
                                : "secondary"
                                } fs-7 fw-semibold`
                            }
                            >
                                {
                                itemProposal && itemProposal.status !== null ? itemProposal.status : "Não identificado"
                                }
                            </span>
                            
                            {/* <i className='bi bi-three-dots fs-3'></i> */}
        
                        </button>
                
                        <div className='dropdown-menu w-150px w-md-150px dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                            <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                            <a
                                className={`btn btn-sm btn-color-muted btn-active btn-active-primary
                                ${itemProposal && itemProposal.status && itemProposal.status === "EM NEGOCIACAO" ? "active" : ""} px-5 me-1 nav-item`
                                }
                                style={{width: '100%'}}
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                    updateProposalOne(itemProposal.id, "EM NEGOCIACAO")
                                    //formik.setValues({...itemProposal, status: "AGUARDANDO"})
                                }}
                            >
                                EM NEGOCIAÇÃO
                                {/* <span 
                                className='badge badge-dark'
                                style={{marginLeft: '10px'}}
                                >
                                AGUARDANDO
                                </span> */}
                            </a>
                        
                        
                            <a
                                className={`btn btn-sm btn-color-muted btn-active btn-active-success 
                                ${itemProposal && itemProposal.status && itemProposal.status === "NEGOCIO FECHADO" ? "active" : ""} px-5 me-1 nav-item`
                                }
                                //className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                                data-bs-toggle="tab"
                                style={{width: '100%'}}
                                href="#"
                                onClick={()=> {
                                    updateProposalOne(itemProposal.id, "NEGOCIO FECHADO")
                                //formik.setValues({...itemProposal, status: "FINALIZADO"})
                                }}
                            >
                                NEGOCIO FECHADO
                                {/* <span 
                                className='badge badge-danger'
                                style={{marginLeft: '10px'}}
                                >
                                {alarmStatus.offlineNum}
                                </span> */}
                            </a>

                            <a
                                className={`btn btn-sm btn-color-muted btn-active btn-active-danger
                                ${itemProposal && itemProposal.status && itemProposal.status === "NEGOCIO RECUSADO" ? "active" : ""} px-5 me-1 nav-item`
                                }
                                //className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                                data-bs-toggle="tab"
                                style={{width: '100%'}}
                                href="#"
                                onClick={()=> {
                                    updateProposalOne(itemProposal.id, "NEGOCIO RECUSADO")
                                //formik.setValues({...itemProposal, status: "FALHA"})
                                }}
                            >
                                NEGOCIO RECUSADO
                                {/* <span 
                                className='badge badge-danger'
                                style={{marginLeft: '10px'}}
                                >
                                {alarmStatus.offlineNum}
                                </span> */}
                            </a>
                            </div>
                        </div>
                      </div>
                      <h3>
                          <span className='text-muted fw-semibold fs-6'>
                              {/* {itemOrderOfService && itemOrderOfService.status ? itemOrderOfService.status : "Não identificado. "} */}
                              {/* <span 
                              className={
                                  `badge badge-light-${
                                      itemOrderOfService && itemOrderOfService.status && itemOrderOfService.status !== null
                                      ? itemOrderOfService.status === "AGUARDANDO"
                                      ? "primary"
                                      : itemOrderOfService.status === "CANCELADO"
                                      ? "danger"
                                      : itemOrderOfService.status === "FINALIZADO"
                                      ? "success"
                                      : itemOrderOfService.status === "AGENDADO"
                                      ? "info"
                                      : itemOrderOfService.status === "EM ANDAMENTO"
                                      ? "warning"
                                      : "secondary"
                                  : "secondary"
                                  } fs-7 fw-semibold`
                              }
                              >
                                  {
                                  itemOrderOfService && itemOrderOfService.status !== null ? itemOrderOfService.status : "Não identificado"
                                  }
                              </span> */}
                          </span>
                      </h3>
              </div>
          </div>
          {/* <div>
              <a
                  onClick={(event: any) => {
                      setShowModalDelete(true)
                      setMyOrderService(itemOrderOfService)
                  }}
                  href='#'
                  className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm'
              >
                  <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-1'
                  />
              </a>
          </div> */}
      </div>

      <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={itemProposal && itemProposal.client ? itemProposal.client.id : undefined} 
          handleClose={() => {
            //setMyClient(null)
            setShowCreateAppModalDetails(false)
            //refresh()
          }} 
        />
    </div>
  );
}