/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { useAuth } from '../../modules/auth';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { getFile } from '../../services/files';
//import { MyPortal } from './components/my-portal'


const IntegradorPage: FC = () => {
    const { currentUser } = useAuth();
    const [imgUrl, setImgUrl] = useState<string | null>(null);
    const [myLinks, setMyLinks] = useState<any>({
      name: null,
      cnpj: null,
      site: null,
      image: null,
      //email: null,
      telephone: null,
      link_facebook: null,
      link_instagram: null,
      link_linkedin: null,
      link_youtube: null,
  })

  const getImage = async (key: string) => {
    try {
      const url = await getFile(key)
      setImgUrl(url.url)

    } catch (error) {
      console.log(error)
    }
  }

  const getMyLinks = async () => {
    const myData = {
      name: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].corporateName ? currentUser?.companies[0].corporateName : null,
      cnpj: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].cnpj ? currentUser?.companies[0].cnpj : null,
      site: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].site ? currentUser?.companies[0].site : null,
      image: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].image_dark ? await getImage(currentUser.companies[0].image_dark) : null,
      
      telephone: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].telephone ? currentUser?.companies[0].telephone : null,
      link_facebook: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_facebook ? currentUser?.companies[0].link_facebook : null,
      link_instagram: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_instagram ? currentUser?.companies[0].link_instagram : null,
      link_linkedin: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_linkedin ? currentUser?.companies[0].link_linkedin : null,
      link_youtube: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_youtube ? currentUser?.companies[0].link_youtube : null,
      //email: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_youtube ? currentUser?.companies[0].link_youtube : null,
    }
    setMyLinks(myData)

    if(currentUser 
      && currentUser.companies 
      && currentUser.companies.length > 0 
      && currentUser.companies[0].image){
        getImage(currentUser.companies[0].image_dark)
    }
  }

  useEffect(() => {
    getMyLinks()
  }, []);


    //id: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].id ? currentUser?.companies[0].id : null,

  return (
    <div className='column g-5 g-xl-8 pt-2'>
        <div className='card p-5'>
          <div>
            <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {imgUrl ? 
                <img src={toAbsoluteUrl(imgUrl)} className='' alt='' /> : 
                <img src={toAbsoluteUrl('/media/imgs/sua-logo-preta.png')} className='' alt='' />
              }
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                  {/* <span className='card-label fs-2'>SOLAR VISION EMPRESA</span> */}
                  <span className='text-muted mt-1 fw-semibold fs-6'>{myLinks.name ?  myLinks.name.toUpperCase() : "INTEGRADOR"}</span>
              </h3>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', marginTop: '2rem'}}>
            {/* {<div className="bg-hover-primary"  style={{padding: '0.5rem',display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                <i className="bi bi-envelope fs-2hx"></i>
                <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                  <span className='card-label fs-5'>bs@brilhosolar.com</span>
                </h3>
              </div>
              <div className='symbol symbol-10px symbol-circle me-5'>
                  <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
              </div>
            </div>} */}

            {myLinks.telephone &&(
              <a href={`tel:${myLinks.telephone}`}>
                <div className="bg-hover-primary"  style={{padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                    <i className="bi bi-telephone-fill fs-2hx"></i>
                    <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                      <span className='card-label fs-5 text-dark'>{myLinks.telephone}</span>
                      {/* <span className='text-muted mt-1 fw-semibold fs-4'>SOLAR VISION EMPRESA</span> */}
                    </h3>
                  </div>
                  <div className='symbol symbol-10px symbol-circle me-5'>
                      <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
                  </div>
                </div>
              </a>
          )}

          {myLinks.link_facebook && (
            <a href={myLinks.link_facebook}>
              <div className="bg-hover-primary"  style={{padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                  <i className="bi bi-facebook fs-2hx"></i>
                  <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                    <span className='card-label fs-5 text-dark'>Facebook</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-4'>SOLAR VISION EMPRESA</span> */}
                  </h3>
                </div>
                <div className='symbol symbol-10px symbol-circle me-5'>
                    <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
                </div>
              </div>
            </a>
          )}

            {myLinks.link_instagram && (
              <a href={myLinks.link_instagram}>
                <div className="bg-hover-primary"  style={{padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                    <i className="bi bi-instagram fs-2hx"></i>
                    <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                      <span className='card-label fs-5 text-dark'>Instagram</span>
                      {/* <span className='text-muted mt-1 fw-semibold fs-4'>SOLAR VISION EMPRESA</span> */}
                    </h3>
                  </div>
                  <div className='symbol symbol-10px symbol-circle me-5'>
                      <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
                  </div>
                </div>
              </a>
            )}

            {myLinks.link_linkedin && (
              <a href={myLinks.link_linkedin}>
                <div className="bg-hover-primary" style={{padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                    <i className="bi bi-linkedin fs-2hx"></i>
                    <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                      <span className='card-label fs-5 text-dark'>Linkedin</span>
                      {/* <span className='text-muted mt-1 fw-semibold fs-4'>SOLAR VISION EMPRESA</span> */}
                    </h3>
                  </div>
                  <div className='symbol symbol-10px symbol-circle me-5'>
                      <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
                  </div>
                </div>
              </a>
            )}
            
            {myLinks.link_youtube && (
              <a href={myLinks.link_youtube}>
                <div className="bg-hover-primary"  style={{padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                    <i className="bi bi-youtube fs-2hx"></i>
                    <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                      <span className='card-label fs-5 text-dark'>Youtube</span>
                      {/* <span className='text-muted mt-1 fw-semibold fs-4'>SOLAR VISION EMPRESA</span> */}
                    </h3>
                  </div>
                  <div className='symbol symbol-10px symbol-circle me-5'>
                      <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
                  </div>
                </div>
              </a>
            )}
            
          </div>
        </div>
    </div>
  )
}

const IntegradorWrapper: FC = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={integradorBreadcrumbs}>Gerenciamento de Unidades (Filiais)</PageTitle> */}
      <IntegradorPage />
    </>
  )
}

export {IntegradorWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

