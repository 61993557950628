/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { getClientAll } from "../../../../../../../../../../services/clientService";
import { getplantsAll } from "../../../../../../../../../../services/plantsService";
import { getUnitBranchAll } from "../../../../../../../../../../services/unitBranchService";
import { Loading } from "../../../../../../../../../components/Loadings/loading";
import { useToast } from "../../../../../../../../../components/Toats";
import { ListPlantsLinked } from "../listPlantsLinked";
import { getCollaboratosAll } from "../../../../../../../../../../services/collaboratorsService";


const StepModal = ({data, hasError, formik}: any) => {
  const [plants, setPlants] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [collaborators, setCollaborators] = useState<any[]>([]);
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [largura, setLargura] = useState(window.innerWidth);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingClients, setLoadingClients] = useState<boolean>(false);
  const [loadingUnitBranchs, setLoadingUnitBranchs] = useState<boolean>(false);
  const [loadingCollaborators, setLoadingCollaborators] = useState<boolean>(false);
  const { toggleToast } = useToast();
  
  const getUsersAllClients = async () => {
    try {
      setLoadingClients(true)
      const MyClient = await getClientAll(formik.values.idUnitBranch ? formik.values.idUnitBranch : "")
      setClients(MyClient)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de clients. ", "error")
      
    }finally{
      setLoadingClients(false)
    }
  }

  const getUsersAllUnitBranchs = async () => {
    try {
      setLoadingUnitBranchs(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoadingUnitBranchs(false)
    }
  }

  const getUsersAllCollaborators = async () => {
    try {
      setLoadingCollaborators(true)
      const collaboratos = await getCollaboratosAll()
      setCollaborators(collaboratos)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os colaboradores. ", "error")

    }finally{
      setLoadingCollaborators(false)
    }
  }

  const getPlants = async () => {
    try {
      setLoading(true)
      let plants = await getplantsAll(`?unitBranch=${formik.values.idUnitBranch ? formik.values.idUnitBranch : ""}&collaborator=${formik.values.idCollaborator ? formik.values.idCollaborator : ""}&client=${formik.values.idClient ? formik.values.idClient : ""}`
      )
      plants = plants.map((plant: any) => {
        plant.imported = false
        return plant
      })
      plants = plants.sort((a: any, b: any) => (b.imported ? 1 : -1))
      setPlants(plants)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar as usinas! ", "error")

    }finally{
      setLoading(false)
    }
  }
  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };


  useEffect(() => {
    const fuction_async = async () =>{
      await getUsersAllClients()
      await getUsersAllUnitBranchs()
      await getPlants()
      await getUsersAllCollaborators()
    }
    fuction_async()

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []);

  useEffect(() => {
    getPlants()
    //getUsersAllClients()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.idUnitBranch, 
    formik.values.idsCollaborator,
    formik.values.idClient
  ]);

  if (loading || loadingClients || loadingUnitBranchs) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100'>

        <div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Filial</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual unidade filial essa credencial será vinculada. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                onChange={formik.handleChange("idUnitBranch")}
              >
                <option value={'1'}></option>
                {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                  return (
                    <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                  )
                })}      
              </select>
            </div>
          </div>
        </div>

        <div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Cliente</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual cliente será o serviço. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.idClient ? formik.values.idClient : '1'}
                onChange={formik.handleChange("idClient")}
              >
                <option value={'1'}></option>
                {clients && clients.length > 0 &&  clients.map((client: any) => {
                  return (
                    <option key={client.id} value={client.id}>{client.nameResponsible}</option>
                  )
                })}
                
              </select>
              {!data.idUnitBranch && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Selecione a unidade!
                </div>
              </div>
            )}
            </div>
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Endereço</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='address'
              placeholder=''
              value={formik.values.address}
              onChange={formik.handleChange("address")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um nome para o cliente
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Titulo</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='title'
              placeholder=''
              value={formik.values.title}
              onChange={formik.handleChange("title")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um título
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Colaborador / Vendedor</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual o colaborador da O.S. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.idCollaborator ? formik.values.idCollaborator : '1'}
                onChange={formik.handleChange("idCollaborator")}
              >
                <option value={'1'}></option>
                {collaborators && collaborators.map((collaborator: any) => {
                  return (
                    <option key={collaborator.id} value={collaborator.id}>{collaborator.name}</option>
                  )
                })}
                
              </select>
              {/* {!data.idUnitBranch && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Selecione o colaborador!
                </div>
              </div>
            )} */}
            </div>
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span >Descrição</span>
                <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Descreva com mais detalhes o serviço'
                ></i>
            </label>
            <input
                style={{height: '20rem'}}
                type='text'
                className='form-control form-control-lg'
                name='description'
                placeholder=''
                value={formik.values.description ? formik.values.description : ""}
                onChange={formik.handleChange("description")}
            />
            {/* {!formik.values.description && hasError && (
            <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                Informe uma descrição para o tipo de serviço
                </div>
            </div>
            )} */}
          </div>
        </div>

        <div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Início</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='datetime-local'
              className='form-control form-control-lg form-control-solid'
              name='dateInit'
              placeholder=''
              value={formik.values.dateInit}
              onChange={formik.handleChange("dateInit")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe uma data de inicio
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Término</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='datetime-local'
              className='form-control form-control-lg form-control-solid'
              name='dateEnd'
              placeholder=''
              value={formik.values.dateEnd}
              onChange={formik.handleChange("dateEnd")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um nome para o cliente
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <div 
              style={{flex: '3'}}
              className={largura < 1200 ? "tab-pane fade show divTableReport" : ""}
              id="user"
              role="tabpanel"
            >
              <ListPlantsLinked plants={plants} className="className='card-xl-stretch bg-body mb-xl-8" formik={formik}/>
          </div>
          
        </div>
        {/* 
          <div style={{display: 'flex', gap: '1rem'}}>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>CPF</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Digite o CPF da Unidade (Filial)'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='cpfResponsible'
                placeholder='000.000.000-00'
                value={formik.values.cpfResponsible}
                onChange={formik.handleChange("cpfResponsible")}
              />
              {!formik.values.cnpj && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    o CPF é obrigatório!
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Telefone</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um telefone para a cliente'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='telephone'
              placeholder='0000-0000'
              value={formik.values.telephone}
              onChange={formik.handleChange("telephone")}
            />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Celular</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Digite um celular para o cliente'
                ></i>
              </label>
              <input
                type='url'
                className='form-control form-control-lg form-control-solid'
                name='cellphone'
                placeholder='(00) 0 0000-0000'
                value={formik.values.cellphone}
                onChange={formik.handleChange("cellphone")}
              />
            </div>
          </div> */}
          
      </div>
    </div>
  )
}

export {StepModal}

