import React, { useState } from 'react';

import styles from './selectGlobal.module.css';
import { SearchInputV2 } from '../SearchInputV2';

type Props = {
    itens: any[] | undefined;
    buttonAll: boolean;
    value: string | number;
    textSearch?: string | null;
    onChange: (value: string) => void;
    labelKey: string; 
    valueKey: string;
}

export function SelectGlobal({
    itens, 
    buttonAll,  
    value,
    textSearch,
    onChange,
    labelKey,
    valueKey
}: Props) {
    const [filterLabel, setFilterLabel] = useState<{ value: string | number, label: string } | null>({
        value: value, 
        label: itens && itens.length > 0 
            ? itens.find((item: any) => item[valueKey] === value)?.[labelKey] || "" 
            : ""
    });

    const [mySearch, setMySearch] = useState<any[]>([]);
    const [control, setControl] = useState<boolean>(false);

    return (
        <div>
            <div className={`me-0 dropdown ${styles.divGlobalSelect}`}>
                <button
                    className={`btn btn-lg btn-light ${styles.buttonMainSelect}`}
                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                >
                    <span className={`${styles.spanButtonMainSelect}`}>
                        {filterLabel && filterLabel.label !== "" ? filterLabel.label : "Selecione uma opção"} 
                    </span>
                    <i className="bi bi-caret-down-fill fs-7"></i>
                </button>

                <div className={`dropdown-menu dropdown-menu-end border border-1 border-light ${styles.divOptionsSelectV1}`} aria-labelledby="dropdownMenuButton1">
                    <div className={`card-toolbar nav nav-tabs ${styles.divOptionsSelectV2}`} data-kt-buttons='true'>
                        <div className={styles.divSearchSelect}>
                            <SearchInputV2
                                className='form-control form-control-sm bg-light'
                                placeholder={textSearch ? textSearch : "Buscar"}
                                myDate={itens}
                                setMySearch={setMySearch}
                                control={control}
                                style={{ width: '100%' }}
                                setControl={setControl}
                                searchParameter={labelKey}
                            />
                        </div>
                        <div className={styles.divOptionsSelectV3}>
                            {buttonAll && (
                                <button
                                    className={`btn btn-sm btn-color-muted btn-active btn-active-primary bg-hover-light text-hover-primary px-5 ${styles.buttonAllSelect}`}
                                    data-bs-toggle="tab"
                                    onClick={() => setFilterLabel(null)}
                                >
                                    <span style={{ textAlign: 'start' }}>
                                        Todos
                                    </span>
                                </button>
                            )}
                            
                            {(control ? mySearch : itens)?.map((item: any) => (
                                <button
                                    key={item[valueKey]}
                                    className={`btn btn-sm btn-color-muted btn-active btn-active-primary bg-hover-light ${styles.buttonAllSelect} ${filterLabel && filterLabel.value === item[valueKey] ? "active" : "text-hover-primary"} px-5`}
                                    data-bs-toggle="tab"
                                    onClick={() => {
                                        setFilterLabel({ value: item[valueKey], label: item[labelKey] || "" });
                                        onChange(item[valueKey]);
                                    }}
                                >
                                    <span className={styles.spanButtonSelectedSelect}>
                                        {item[labelKey] || "Não identificado"} 
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
