import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

const SidebarLogoFooter = () => {
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  return (
    <div className='app-sidebar-logo' id='kt_app_sidebar_logo'>
      <Link to='/dashboard' style={{width: '100%'}}>
        {config.layoutType === 'dark-sidebar' ? (
          // <img
          //   alt='Logo'
          //   src={toAbsoluteUrl('/media/logos/default-dark.svg')}
          //   className='h-25px app-sidebar-logo-default'
          // />
            <div>
                {/* {toggleType === "" && 
                <h3 style={{display: "flex", flexDirection: "column"}}>
                    <span className='text-muted fw-semibold fs-7'>Powered by</span>
                </h3>} */}
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/imgs/novalogo.png')}
                    className='h-50px app-sidebar-logo-default'
                    style={{width: '100%'}}
                />

            </div>
          
        ) : (
          <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/imgs/logo.png')}
              className='h-50px app-sidebar-logo-default'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/imgs/logo.png')}
              className='h-50px app-sidebar-logo-default'
            />
          </>
        )}

        <img
            alt='Logo'
            src={toAbsoluteUrl('/media/imgs/sub-logo-new.png')}
            className='h-45px app-sidebar-logo-minimize'
        />
        
      </Link>


      {/* {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
        </div>
      )} */}
    </div>
  )
}

export {SidebarLogoFooter}
