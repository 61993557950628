/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { getTypeServicesAll } from "../../../../../../../../../../../../services/typeServices";
import { Loading } from "../../../../../../../../../../../components/Loadings/loading";
import { useToast } from "../../../../../../../../../../../components/Toats";
import { getProductsAll } from "../../../../../../../../../../../../services/productsService";


const StepModal = ({data, hasError, formik,
  setTypeServicesRaiz, setProductsRaiz}: any) => {

  const [typeServices, setTypeServices] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [largura, setLargura] = useState(window.innerWidth);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTypeServices, setLoadingTypeServices] = useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const { toggleToast } = useToast();
  
  const getTypeServices = async () => {
    try {
      setLoadingTypeServices(true)
      const MyTypeServices = await getTypeServicesAll()
      setTypeServices(MyTypeServices)
      setTypeServicesRaiz(MyTypeServices)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de serviços prestados. ", "error")
      
    }finally{
      setLoadingTypeServices(false)
    }
  }


  const getProducts = async () => {
    try {
      setLoadingProducts(true)
      const MyProducts = await getProductsAll()
      setProducts(MyProducts)
      setProductsRaiz(MyProducts)
      
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de produtos da empresa. ", "error")
      
    }finally{
      setLoadingProducts(false)
    }
  }

  // const getUsersAllUnitBranchs = async () => {
  //   try {
  //     setLoadingUnitBranchs(true)
  //     const unitBranchs = await getUnitBranchAll()
  //     setUnitBranchs(unitBranchs)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar a lista de unidades. ", "error")

  //   }finally{
  //     setLoadingUnitBranchs(false)
  //   }
  // }

  // const getPlants = async () => {
  //   try {
  //     setLoading(true)
  //     let plants = await getplantsAll()
  //     plants = plants.map((plant: any) => {
  //       plant.imported = false
  //       return plant
  //     })
  //     plants = plants.sort((a: any, b: any) => (b.imported ? 1 : -1))
  //     setPlants(plants)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar as usinas! ", "error")

  //   }finally{
  //     setLoading(false)
  //   }
  // }
  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };


  useEffect(() => {
    const fuction_async = async () =>{
      //await getUsersAllClients()
      //await getUsersAllUnitBranchs()
      //await getPlants()
      await getTypeServices()
      await getProducts()
    }
    fuction_async()

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []);

  if (loading || loadingTypeServices) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100'>

      <div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Tipo de Item</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione o serviço. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.tipo ? formik.values.tipo : ''}
                onChange={formik.handleChange("tipo")}
              >
                <option value={''}></option>
                <option value={'SERVICO'}>Serviço</option>
                <option value={'PRODUTO'}>Produto</option>
                
                
              </select>
              {/* {!data.idUnitBranch && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Selecione o serviço!
                </div>
              </div>
            )} */}
            </div>
          </div>
        </div>
        
        {formik.values.tipo === "SERVICO" &&
        (<div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Serviço</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione o serviço. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.idTypeService ? formik.values.idTypeService : '1'}
                onChange={formik.handleChange("idTypeService")}
              >
                <option value={'1'}></option>
                {typeServices && typeServices.map((typeService: any) => {
                  return (
                    <option key={typeService.id} value={typeService.id}>{typeService.description}</option>
                  )
                })}
                
              </select>
              {!data.idUnitBranch && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Selecione o serviço!
                </div>
              </div>
            )}
            </div>
          </div>
        </div>)}

        {formik.values.tipo === "PRODUTO" &&
        (<div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Produto</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione o serviço. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.idProduct ? formik.values.idProduct : '1'}
                onChange={formik.handleChange("idProduct")}
              >
                <option value={'1'}></option>
                {products && products.map((product: any) => {
                  return (
                    <option key={product.id} value={product.id}>{product.description}</option>
                  )
                })}
                
              </select>
              {/* {!data.idUnitBranch && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Selecione o serviço!
                </div>
              </div>
            )} */}
            </div>
          </div>
        </div>)}


        <div>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span >Descrição</span>
                <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Descreva com mais detalhes o serviço'
                ></i>
            </label>
            <input
                style={{height: '20rem'}}
                type='text'
                className='form-control form-control-lg'
                name='description'
                placeholder=''
                value={formik.values.description ? formik.values.description : ""}
                onChange={formik.handleChange("description")}
            />
            {/* {!formik.values.description && hasError && (
            <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                Informe uma descrição para o tipo de serviço
                </div>
            </div>
            )} */}
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Quantidade</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              name='amount'
              placeholder=''
              value={formik.values.amount}
              onChange={formik.handleChange("amount")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe uma quantidade!
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Preço</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um preço para o serviço. '
              ></i>
            </label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              name='value'
              placeholder=''
              value={formik.values.value}
              onChange={formik.handleChange("value")}
            />
            {!formik.values.value && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um preço.
                </div>
              </div>
            )}
          </div>

          {/* <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Subtotal</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='sub_value'
              placeholder=''
              value={formik.values.sub_value}
              onChange={formik.handleChange("sub_value")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um título
                </div>
              </div>
            )}
          </div> */}
        </div>

        {/* <div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Início</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='datetime-local'
              className='form-control form-control-lg form-control-solid'
              name='dateInit'
              placeholder=''
              value={formik.values.dateInit}
              onChange={formik.handleChange("dateInit")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe uma data de inicio
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Término</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='datetime-local'
              className='form-control form-control-lg form-control-solid'
              name='dateEnd'
              placeholder=''
              value={formik.values.dateEnd}
              onChange={formik.handleChange("dateEnd")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um nome para o cliente
                </div>
              </div>
            )}
          </div>
        </div> */}
          
      </div>
    </div>
  )
}

export {StepModal}

