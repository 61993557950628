import api from "./api";

export const getInvoicesAll = async(idUnitBranch?: string, mes?: Date, concessionarie?: string, plant?: string, status?: string, statusUnitConsmer?: string) => {
    const response = await api.get(`invoices/listAll?mes=${mes ? mes : ""}&idUnitConsumer=${idUnitBranch ? idUnitBranch === '1' ? "" : idUnitBranch : ""}&status=${status ? status : ""}&concessionarie=${concessionarie ? concessionarie : ""}&plant=${plant ? plant : ""}`);
    return response.data;
};

export const getInvoicesAllPagination = async(idUnitBranch?: string, mes?: Date, concessionarie?: string, plant?: string, status?: string, statusUnitConsmer?: string, confirm_payment?: string, limit?: number, page?: number) => {
    const response = await api.get(`invoices/listAllPagination?mes=${mes ? mes : ""}&idUnitConsumer=${idUnitBranch ? idUnitBranch === '1' ? "" : idUnitBranch : ""}&concessionarie=${concessionarie ? concessionarie : ""}&plant=${plant ? plant : ""}&status=${status ? status : ""}&statusUnitConsumer=${statusUnitConsmer ? statusUnitConsmer : ""}&confirm_payment=${confirm_payment ? confirm_payment : ""}&limit=${limit ? limit : ""}&page=${page ? page : ""}`);
    return response.data;
};

export const updateInvoice = async(id: string, data: any) => {
    const response = await api.put(`invoices/${id}`, data);
    return response.data;
};

export const getInvoicePDF = async(key: string) => {
    const response = await api.get(`downloadInvoices/link/${key}`);
    return response.data;
};

export const getInvoicePDFFile = async(key: string) => {
    const response = await api.get(`downloadInvoices/file/${key}`, {
        responseType: 'blob'
    });
    return response;
};

export const getInvoicePDFBase64 = async(key: string) => {
    const response = await api.get(`downloadInvoices/base64/${key}`);
    return response.data;
};


export const putInvoiceExtract = async(key: string, data: any) => {
    const response = await api.put(`invoices/updateOneExtract/${key}`, data);
    return response.data;
};

export const getInvoicesOneFilter = async(unitConsumer?: string, mes?: Date) => {
    const response = await api.get(`invoices/listOneFilter?mes=${mes ? mes : ""}&unitConsumer=${unitConsumer ? unitConsumer : ""}`);
    return response.data;
};


