import axios from "axios";
import api from "./api"

export const getfileUrl = async (file: any) => {
    const response = await api.post(`uploads/`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
};

export const getfileCloundUrl = async (file: any, pasta?: string) => {
  const response = await api.post(`uploads/uploadClound/?pasta=${pasta ? pasta : ""}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const getfileCloundDocxUrl = async (file: any, pasta?: string, nameArquive?: string, format?: string) => {
  const response = await api.post(`uploads/uploadCloundDocx/?pasta=${pasta ? pasta : ""}&nameArquive=${nameArquive ? nameArquive : ""}&format=${format ? format : ""}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const getfileBase64 = async (file: any) => {
  const response = await api.post(`uploads/base64/`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};


export const getDocumentInvoice = async(key: string) => {
  const response = await api.get(`downloadInvoices/link/${key}`);
  return response.data;
};

export const uploadDocumentInvoice = async (file: any) => {
  const response = await api.post(`downloadInvoices/upload/`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const convertHtmlPdf = async (body: any) => {
  const response = await api.post(`uploads/convert-html-to-pdf/`, 
    body, {
      headers: {
        'Content-Type': "application/json"
      },
      responseType: 'blob'
    }
  );
  return response.data;
};

export const fetchFile = async (url: string) => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      withCredentials: false, 
    });

    const blob = response.data; 
    return blob;
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error);
    throw error;
  }
};



