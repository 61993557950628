/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getReportServicesAll } from '../../../../../../services/reportService';
import { SearchInput } from '../../../../../components/SearchInput';
import { useToast } from '../../../../../components/Toats';
import { ModalRequestService } from '../ModalDetailsRequestService';
import { ModalAddUpdateOrderService } from '../TableOrderOfService/components/ModalAddEditOrderService';
import { ModalStatusRequestService } from './components/ModalStatusRequestService';
import { TableBody } from './TableBody';
import { useAuth } from '../../../../../../modules/auth';
//import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';


type Props = {
  className: string;
  refresh: any
  refreshPage : boolean; 
  setRefreshPage: any;
}

const TableReportServices: React.FC<Props> = ({className, refreshPage}) => {
  
  const [mySearch, setMySearch] = useState([]);
  const [control, setControl] = useState(false);
//   const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
//   const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  //const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myReportService, setMyReportService] = useState<any | null>(null);
  const [reportServices, setReportServices] = useState<any>([]);
  const { toggleToast } = useToast();
  const [reportServiceLoading, setReportServicesLoading] = useState<boolean>(false)
  const [largura, setLargura] = useState(window.innerWidth);
  const [showModalDetails, setShowModalDetails] = useState<boolean>(false)
  const [showModalStatus, setShowModalStatus] = useState<boolean>(false)
  const [showModalCreateOS, setShowModalCreateOS] = useState<boolean>(false)
  const {currentUser} = useAuth()

const getReportServices = async () => {
  try {
    setReportServicesLoading(true)
    const myReportServices = await getReportServicesAll()
    setReportServices(myReportServices)

  } catch (error) {
    console.log(error)
    toggleToast("Erro ao buscar as solicitações de serviços!", "error")
  }finally{
    setReportServicesLoading(false)
  }
}


const refresh = () => {
  getReportServices()
}

const atualizarTamanhoDaTela = () => {
  setLargura(window.innerWidth);
};

useEffect(() => {
  getReportServices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
  getReportServices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [refreshPage]);

useEffect(() => {
  window.addEventListener('resize', atualizarTamanhoDaTela);
  return () => {
    window.removeEventListener('resize', atualizarTamanhoDaTela);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
  
  return (
    <div className={`${className}`}>
      {/* begin::Header */}
      <div className='border-0'>
        {/*<h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Clientes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{clients.length} Cliente(s)</span>
        </h3>*/}
        <div 
          style={{
            display: 'flex', 
            gap: '1rem', 
            alignItems: largura < 1200 ? 'start' : 'center', 
            width: "100%", 
            justifyContent: 'space-between',
            flexDirection: largura < 1200 ? 'column' : 'row',
            paddingBottom: '2rem',
          }}>
            {largura > 1200 && (
              <h3 className='align-items-start flex-column' style={{display: "flex", marginBottom: '2rem'}}>
                <span className='card-label fw-bold fs-4 mb-1'>Lista de Solicitações</span>
                <span className='text-muted fw-semibold fs-7'>Todas as solicitações/chamados realizado(a)s </span>
              </h3>
          )}

          <SearchInput 
            className={`form-control form-control-solid bg-body ps-14 ${largura < 1200 ? 'form-control-sm': ''}`}
            placeholder='Buscar por tipo de s.s'
            myDate={reportServices}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura < 1200 ? "100%" : "300px"}}
            setControl={setControl}
            searchParameter="typeService.description"
          />

          {/* <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={{width: largura > 1200 ? '' : '100%', display: 'flex', justifyContent: 'end'}}
          >
            <a
              //onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Novo Tipo de Serviço
            </a>
          </div> */}
        </div>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='py-3' style={largura < 1200 ? {height: '27rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto', height: '35rem'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th> */}
                <th className={largura < 1200 ? 'min-w-150px' : 'min-w-250px'}>T.S solicitado</th>
                {currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' && <th className='min-w-200px'>Data</th>}
                {<th className='min-w-80px'>Status</th>}
                {currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' && <th className='min-w-150px'>Cliente</th>}
                {currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' && <th className='min-w-100px text-end'>Actions</th>}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                reportServiceLoading ? 
                  <>
                    Carregando....
                  </> 
                
                :
                control === false ? (
                  <TableBody 
                    reportServices={reportServices} 
                    setMyReportService={setMyReportService}
                    //setShowCreateAppModal={setShowCreateAppModal}
                    //setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowModalStatus={setShowModalStatus}
                    setShowCreateAppModalDelete={() => {}}
                    setShowModalDetails={setShowModalDetails}
                    setShowModalCreateOS={setShowModalCreateOS}
                  />
                )
                : (
                  <TableBody 
                    reportServices={mySearch} 
                    setMyReportService={setMyReportService}
                    //setShowCreateAppModal={setShowCreateAppModal}
                    //setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowModalStatus={setShowModalStatus}
                    setShowCreateAppModalDelete={() => {}}
                    setShowModalDetails={setShowModalDetails}
                    setShowModalCreateOS={setShowModalCreateOS}
                  />
                )
                }
              
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
        <ModalRequestService
          show={showModalDetails}
          data={myReportService}
          id={myReportService ? myReportService.id : undefined} 
          handleClose={() => {
            setMyReportService(null)
            setShowModalDetails(false)
            refresh()
          }} 
        />

        <ModalStatusRequestService 
          show={showModalStatus}
          data={myReportService}
          handleClose={() => {
            setMyReportService(null)
            setShowModalStatus(false)
            refresh()
          }} 
        />

        <ModalAddUpdateOrderService
            show={showModalCreateOS}
            id={undefined} 
            data={{
              description: myReportService && myReportService.description ? myReportService.description : null,
              title: null,
              address: null,
              dateInit: null,
              dateEnd: null,
              idClient: myReportService && myReportService.client && myReportService.client.id ? myReportService.client.id : null,
              idUnitBranch: null,
              idPlant: myReportService && myReportService.plant && myReportService.plant.id ? myReportService.plant.id : null,
              idCollaborator: null
            }}
            handleClose={() => {
              setMyReportService(null)
              setShowModalCreateOS(false)
              refresh()
            }} 
        />
        {/* <ModalAddUpdateClient
          show={showCreateAppModal}
          id={myTypeService ? myTypeService.id : undefined} 
          data={myTypeService ? myTypeService : undefined}
          handleClose={() => {
            setMyTypeService(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        /> */}
        {/* <ModalDeleteTypeService 
          show={showCreateAppModalDelete}
          data={myTypeService}
          id={myTypeService ? myTypeService.id : undefined} 
          handleClose={() => {
            setMyTypeService(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        /> */}

        {/* <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        /> */}
      {/* begin::Body */}
    </div>
  )
}

export {TableReportServices}
