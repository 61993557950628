/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { initialLetters } from '../../../../../utils/initialLetters';
import { NotImported } from '../../../../components/NotInformat';
import { getProposalOne } from '../../../../../services/proposalsService';
import { getFileBase64 } from '../../../../../services/files';
import axios from 'axios';
import { linkPDF, linkPDFV2 } from '../../../../../utils/savePDF';
import { formatarData } from '../../../../../utils/date';
import { LoadingSpan } from '../../../../components/Loadings/loadingSpan';
import { getInvoicePDFFile } from '../../../../../services/invoices';
import JSZip from 'jszip';
import { postConvertPdfV3 } from '../../../../../services/ConvertPDFService';
import { getfileUrl } from '../../../../../services/uploadService';
import useSize from '../../../../../hooks/useSize';

type Props = {
    proposals: any[];
    setMyProposals: any;
    setShowCreateAppModal: any;
    setShowCreateAppModalDelete: any
    setShowCreateAppModalDetails: any
    setShowDocumentAppModalTemplate: any
}

export function TableBody({
    proposals, 
    setMyProposals, 
    setShowCreateAppModal, 
    setShowCreateAppModalDelete, 
    setShowCreateAppModalDetails,
    setShowDocumentAppModalTemplate
}: Props) {

    const [loadingPDF, setLoadingPDF] = useState<boolean>(false)
    const [idProposal, setIdProposal] = useState<any>(null)
    const {width} = useSize()

    const getProposal = async (id: string) => {
        try {
          if(id){
            const myProposal = await getProposalOne(id ? id : "")
            return myProposal
          }
        } catch (error) {
            console.log(error)
        }
    }

    const getImage = async (key: string) => {
        try {
          const url = await getFileBase64(key)
          return url.base64
    
        } catch (error) {
          console.log(error)
          return ""
        }
    }

    const replaceValues = async (dados: any, html: string) => {
        let novoHtml = html;
        const imageCompany = dados 
            && dados.unitBranch 
            && dados.unitBranch.company 
            && dados.unitBranch.company.image 
            ? await getImage(dados.unitBranch.company.image_dark)
            : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs";
    
        let dados_OS = {
            //imageCompany: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.image ? dados.unitBranch.company.image : "/media/imgs/sub-logo-v2.png",
            imageCompany: imageCompany ? `data:image/jpeg;base64,${imageCompany}` : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs",
            corporateName: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.corporateName ? dados.unitBranch.company.corporateName : "",
            cnpj: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.cnpj ? dados.unitBranch.company.cnpj : "",
            street: dados && dados.unitBranch && dados.unitBranch.street ? dados.unitBranch.street : "",
            district: dados && dados.unitBranch && dados.unitBranch.district ? dados.unitBranch.district : "",
            number: dados && dados.unitBranch && dados.unitBranch.number ? dados.unitBranch.number : "",
            telephone: dados && dados.unitBranch && dados.unitBranch.telephone ? dados.unitBranch.telephone : "",
            emailCompany: dados && dados.unitBranch && dados.unitBranch.emailCompany ? dados.unitBranch.emailCompany : "",
            number_order_service: dados && dados.number_order_service ? dados.number_order_service : "",
            dateCreate: dados && dados.created_at ? formatarData(dados.created_at) : "",
            nameResponsible: dados && dados.client && dados.client.nameResponsible ? dados.client.nameResponsible : "",
            cpfResponsible: dados && dados.client && dados.client.cpfResponsible ? dados.client.cpfResponsible : "",
            telephoneClient: dados && dados.client && dados.client.telephone ? dados.client.telephone : "0000-000",
            cellphoneClient: dados && dados.client && dados.client.cellphone ? dados.client.cellphone : "(00) 0 0000-0000",
            emailClient: dados && dados.client && dados.client.email ? dados.client.email : "",
            namePlant: dados && dados.plant && dados.plant.name ? dados.plant.name : "",
            addressPlant: dados && dados.plant && dados.plant.address ? dados.plant.address : "",
            titulo: dados && dados.title ? dados.title : "",
            status: dados && dados.status ? dados.status : "",
            description: dados && dados.description ? dados.description : "",
            companyName: dados && dados.unitBranch && dados.unitBranch.companyName ? dados.unitBranch.companyName : "",
            value_sub_final: dados && dados.value_sub_final ? dados.value_sub_final : "0",
            value_discont: dados && dados.value_discont ? dados.value_discont : "0",
            value_final: dados && dados.value_final ? dados.value_final : "0",
            itensProposal: dados && dados.itensProposal ? 
            dados.itensProposal.map((item: any) => {
                const my_item = {
                    service: item ? 
                            item.tipo === "SERVICO" ? 
                                item.typeService && item.typeService.description ? item.typeService.description 
                            : "" 
                        : item.tipo === "PRODUTO" ? 
                            item.product && item.product.description ? item.product.description 
                            : "" 
                        : ""  
                    : "",
                    description: item && item.description ? item.description : "",
                    value: item && item.value ? item.value : "",
                    amount: item && item.amount ? item.amount : "",
                    sub_value: item && item.sub_value ? item.sub_value : "",
                }
                return my_item
            }) : []
    
        }
    
        Object.keys(dados_OS).forEach(chave => {
            if(chave === 'itensProposal'){
                const servicesHtml = dados_OS[chave].map((service: any) => {
                    const tds = Object.keys(service).map(key => {
                      
                        if(key === 'sub_value' || key === 'value'){
                            return `<td>R$ ${service[key]},00</td>`
                        }else{
                            return `<td>${service[key]}</td>`
                        }
    
                      //return `<td>${report[key]}</td>`;
                    }).join('');
                    return `<tr>${tds}</tr>`;
                  }).join('');
        
                  novoHtml = novoHtml.replace(
                    `{{${chave}}}`, 
                    `<table style="width: 100%;">
                        <thead>
                            <tr>
                                <th style="background: #e1e3ea;">Serviço/Produto</th>
                                <th style="background: #e1e3ea;">Descrição</th>
                                <th style="background: #e1e3ea;">Valor Unit.</th>
                                <th style="background: #e1e3ea;">Qtd</th>
                                <th style="background: #e1e3ea;">Total</th>
                            </tr>
                        </thead>
                        <tbody id="table_services">${servicesHtml}</tbody>
                    </table>`
                    // `<tbody id="table_services">${servicesHtml}</tbody>`
                  );
            }else{
                const marcador = new RegExp(`{{${chave}}}`, 'g');
                novoHtml = novoHtml.replace(marcador, dados_OS[chave] );
            }
        });
        return novoHtml;
    }

    const fetchHtmlAsString = async () => {
        try {
          const response = await axios.get('/media/HTMLs/template_PRO.html', { responseType: 'text' });
          return response.data;
        } catch (error) {
          console.error('Error fetching HTML file:', error);
          return null;
        }
    };

    const processDocx = async (fileOrUrl: any, placeholders: any) => {
        try {
          let file;
      
          if (fileOrUrl && typeof fileOrUrl === "string") {
            
            // const response = await fetch(fileOrUrl);
            // const blob = await response.blob();
            // file = new File([blob],  "original-document.docx", { type: blob.type });
    
            // const uploadResponse = await getInvoicePDF(fileOrUrl);
            // if(uploadResponse && uploadResponse.url){
            //   const response = await fetch(uploadResponse.url);
            //   const blob = await response.blob();
            //   file = new File([blob], "original-document.docx", { type: blob.type });
            // }
    
            const response: any = await getInvoicePDFFile(fileOrUrl);
              if (response) {
                const blob = response.data;
                file = new File([blob], "original-document.docx", { type: blob.type });
              }
          }
          
          if(file){
            const arrayBuffer = await file.arrayBuffer();
            const zip: any = await JSZip.loadAsync(arrayBuffer);
        
            const documentXml = await zip.file("word/document.xml").async("string");
            let updatedXml = documentXml;
        
            for (const [placeholder, value] of Object.entries(placeholders)) {
              const regex = new RegExp(`{{${placeholder}}}`, "g");
              updatedXml = updatedXml.replace(regex, value);
            }
      
            zip.file("word/document.xml", updatedXml);
            const updatedDocx = await zip.generateAsync({ type: "blob" });
        
            const pdfFormData = new FormData();
            pdfFormData.append("file", updatedDocx, "processed-document.docx");
        
            const response = await postConvertPdfV3(pdfFormData);
        
            const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        
            const uploadFormData = new FormData();
            uploadFormData.append("file", pdfBlob, "final-document.pdf");
        
            const uploadResponse = await getfileUrl(uploadFormData);
    
            if (uploadResponse && uploadResponse.url) {
              let pdfUrl = uploadResponse.url;
        
              if(width && width < 1000){
                const url = new URL(pdfUrl);
                url.searchParams.append('open_external_app', 'true');
                pdfUrl = url.toString();
              }
              window.open(pdfUrl, '_blank', 'noopener,noreferrer');
            } else {
              console.error('Erro ao obter a URL do arquivo após o upload.');
            }
            console.log("Documento processado e convertido para PDF com sucesso!");
          }
        } catch (error) {
          console.error("Erro ao processar o documento:", error);
        }
    };
    
    const substituteValues = async (file: any, dados: any) => {
    if (file) {
        try {
        let placeholderData = {
            corporateName: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.corporateName ? dados.unitBranch.company.corporateName : "",
            cnpj: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.cnpj ? dados.unitBranch.company.cnpj : "",
            street: dados && dados.unitBranch && dados.unitBranch.street ? dados.unitBranch.street : "",
            district: dados && dados.unitBranch && dados.unitBranch.district ? dados.unitBranch.district : "",
            number: dados && dados.unitBranch && dados.unitBranch.number ? dados.unitBranch.number : "",
            telephone: dados && dados.unitBranch && dados.unitBranch.telephone ? dados.unitBranch.telephone : "",
            emailCompany: dados && dados.unitBranch && dados.unitBranch.emailCompany ? dados.unitBranch.emailCompany : "",
            number_order_service: dados && dados.number_order_service ? dados.number_order_service : "",
            dateCreate: dados && dados.created_at ? formatarData(dados.created_at) : "",
            nameResponsible: dados && dados.client && dados.client.nameResponsible ? dados.client.nameResponsible : "",
            cpfResponsible: dados && dados.client && dados.client.cpfResponsible ? dados.client.cpfResponsible : "",
            telephoneClient: dados && dados.client && dados.client.telephone ? dados.client.telephone : "0000-000",
            cellphoneClient: dados && dados.client && dados.client.cellphone ? dados.client.cellphone : "(00) 0 0000-0000",
            emailClient: dados && dados.client && dados.client.email ? dados.client.email : "",
            namePlant: dados && dados.plant && dados.plant.name ? dados.plant.name : "",
            addressPlant: dados && dados.plant && dados.plant.address ? dados.plant.address : "",
            titulo: dados && dados.title ? dados.title : "",
            status: dados && dados.status ? dados.status : "",
            description: dados && dados.description ? dados.description : "",
            companyName: dados && dados.unitBranch && dados.unitBranch.companyName ? dados.unitBranch.companyName : "",
            value_sub_final: dados && dados.value_sub_final ? dados.value_sub_final : "0",
            value_discont: dados && dados.value_discont ? dados.value_discont : "0",
            value_final: dados && dados.value_final ? dados.value_final : "0",
            ...(
                dados &&
                dados &&
                dados.itensProposal
                    ? dados.itensProposal.reduce((acc, item, index) => {
                        const idx = index + 1;
                        return {
                            ...acc,
                            [`itemDescription${idx}`]: item && item.description ? item.description : "",
                            [`itemService${idx}`]: item && item.tipo === "SERVICO" && item.typeService && item.typeService.description ? item.typeService.description : "",
                            [`itemProduct${idx}`]: item && item.tipo === "PRODUTO" && item.product && item.product.description ? item.product.description : "",
                            [`itemValue${idx}`]: item && item.value ? item.value : "",
                            [`itemAmount${idx}`]: item && item.amount ? item.amount : "",
                            [`itemSubValue${idx}`]: item && item.sub_value ? item.sub_value : "",
                        };
                    }, {})
                    : {}
            )
        };

        await processDocx(file, placeholderData);
        } catch (error) {
        console.error("Erro ao processar o documento:", error);
        }
    }
    }

    // const previewPDF = async (itemProposal: any) => {
    //     try {
    //       const template = itemProposal 
    //       && itemProposal.templatesProposals 
    //       && itemProposal.templatesProposals.length > 0 
    //       ? itemProposal.templatesProposals[0].textHTML 
    //       : await fetchHtmlAsString();
    
    //       const template_modificad = await replaceValues(itemProposal, template)
    //       const myReturnLink = await linkPDF(template_modificad ? template_modificad : "<h1>Template não encontrado! </h1>")
    //       window.open(myReturnLink, '_blank');
          
    //       //setUrl(myReturnLink) 
        
    //     } catch (error) {
    //       console.log(error)  
    //     }
    // }

    const previewPDF = async (itemProposal: any) => {
        try {
          const myTemplate = itemProposal 
          && itemProposal.templatesProposals 
          && itemProposal.templatesProposals.length > 0
          ? itemProposal.templatesProposals[0]
          : null
  
          if(myTemplate){
            if(myTemplate.typeDoc && myTemplate.typeDoc === 'html'){
              const templateHTML = itemProposal 
              && itemProposal.templatesProposals 
              && itemProposal.templatesProposals.length > 0 
              ? itemProposal.templatesProposals[0].textHTML 
              : await fetchHtmlAsString();
    
              const template_modificad = await replaceValues(itemProposal, templateHTML)
              await linkPDFV2(template_modificad ? template_modificad : "<h1>Template não encontrado! </h1>")
            }else{
              if(myTemplate.typeDoc && myTemplate.typeDoc === 'docx'){
                await substituteValues(myTemplate.linkDocx, itemProposal)
              }
            }
          }
        } catch (error) {
          console.log(error)  
        }
    }

    const handleButtonClickDelete = (event, proposal) => {
        event.stopPropagation();
        
        setMyProposals(proposal)
        setShowCreateAppModalDelete(true)
    };

    const handleButtonClickDetails = (event, proposal) => {
        event.stopPropagation();
        
        setMyProposals(proposal)
        setShowCreateAppModalDetails(true)
    };

    const handleButtonClickUpdate = (event, proposal) => {
        event.stopPropagation();

        proposal.idPhaseFunnel = proposal.phaseFunnel ? proposal.phaseFunnel.id : null; 
        proposal.idsCollaborators = proposal.collaborators && proposal.collaborators.length > 0 ? proposal.collaborators.map((collaborator: any) => collaborator.id) : []
        proposal.idPlant = proposal.plant ? proposal.plant.id : null;
        proposal.idClient = proposal.client ? proposal.client.id : null;
        proposal.idUnitBranch = proposal.unitBranch ? proposal.unitBranch.id : null;
        proposal.idsCollaborator = proposal.collaborators && proposal.collaborators.length > 0 ? [proposal.collaborators[0].id] : null;
        
        setMyProposals(proposal)
        setShowCreateAppModal(true)
    };

    const handleButtonClickTemplate = (event, proposal) => {
        event.stopPropagation();
        setMyProposals(proposal)

        setShowDocumentAppModalTemplate(true)
    }

    const handleButtonClickDownload = async (event, proposal) => {
        setLoadingPDF(true)
        if(proposal && proposal.id){
            const myProposal = await getProposal(proposal.id)
            await previewPDF(myProposal)
        }
        setLoadingPDF(false)
        setIdProposal(null)
    }

  return (
    <tbody>
        {
        proposals.length !== 0 ?   
            proposals.map((proposal: any) => {
                return (
                    <tr 
                        // onClick={() =>{
                        //     setMyProposals(proposal)
                        //     setShowCreateAppModalDetails(true)
                        // }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <KTSVG path="/media/icons/duotune/general/gen005.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {/* {unitBranch.companyName} */}
                                    {proposal.title ? proposal.title : <NotImported className="fs-5 fw-bolder"/>}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {/* {unitBranch.dateOfBirth} */}
                                </span>
                            </div>
                            </div>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {/* {unitBranch.cnpj} */}R$ {" "}{proposal.value_final ? proposal.value_final : 0},00
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                CNPJ da filial
                            </span> */}
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {/* {unitBranch.street}, {unitBranch.district} */}
                            {proposal.phaseFunnel && proposal.phaseFunnel.description ? proposal.phaseFunnel.description : <NotImported className="fs-5 fw-bolder"/>}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {/* {unitBranch.city}, {unitBranch.country} */}
                            </span>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                                {proposal && proposal.collaborators && proposal.collaborators.length > 0 ? 
                                    proposal.collaborators.map((collaborator : any) => {
                                        return (
                                            <div>
                                                <div className='symbol symbol-50px symbol-circle me-5'>
                                                    {/* <img src={toAbsoluteUrl("/media/avatars/300-6.jpg")} /> */}

                                                    <div className='symbol symbol-40px symbol-circle '>
                                                        <span className={`symbol-label bg-light-primary text-primary fs-4 fw-bolder bg-hover-primary text-hover-light`}>
                                                            {initialLetters(collaborator.name)}
                                                        </span>
                                                        {/* <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                : <NotImported className="fs-6 fw-bold"/>}
                            </div>
                        </td>
                        
                        <td>
                            {/* <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                Em Negociação
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            </span>  */}
                            <span 
                                className={
                                    `badge badge-light-${
                                        proposal.status !== null
                                        ? proposal.status === "NEGOCIO FECHADO"
                                        ? "success"
                                        : proposal.status === "NEGOCIO RECUSADO"
                                        ? "danger"
                                        : proposal.status === "EM NEGOCIACAO"
                                        ? "primary"
                                        : "secondary"
                                        : "secondary"
                                    } fs-7 fw-semibold`
                                }
                            >
                                {
                                proposal.status ? proposal.status : <NotImported className="fs-6 fw-bolder"/>
                                }
                            </span>
                        </td>
            
                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={(event: any) => {
                                        handleButtonClickDetails(event, proposal)
                                    }}
                                >
                                    {/* <KTSVG
                                    path='/media/icons/duotune/general/gen019.svg'
                                    className='svg-icon-3'
                                    /> */}
                                    <i className={`bi bi-eye-fill`} style={{fontSize: '1.2rem'}}></i>
                                </a>
                                <button
                                    disabled={loadingPDF}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={(event: any) => {
                                        setIdProposal(proposal.id)
                                        handleButtonClickDownload(event, proposal)
                                    }}
                                >
                                    {loadingPDF && idProposal && idProposal === proposal.id ? <LoadingSpan /> : <i className='bi bi-filetype-pdf' style={{fontSize: '1.2rem'}}></i>}
                                </button>
                                <a
                                    onClick={(event: any) =>{
                                        handleButtonClickUpdate(event, proposal)
                                    }}
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </a>
                                <a
                                    onClick={(event: any) => {
                                        handleButtonClickDelete(event, proposal)
                                    }}
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                    />
                                </a>

                                <a
                                    onClick={(event: any) => {
                                        //handleButtonClickDelete(event, proposal)
                                        handleButtonClickTemplate(event, proposal)
                                    }}
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                >
                                    <KTSVG
                                    path='/media/icons/duotune/text/txt009.svg'
                                    className='svg-icon-3'
                                    />
                                </a>
                            </div>
                        </td>
                    </tr>
                )
            })

        :
            <tr>
                <td colSpan={6} style={{textAlign: 'center'}}>
                <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                    <span className='text-muted fw-semibold text-muted d-block fs-4'>
                        Propostas não encontradas!
                    </span>
                </div>
            </td>
            </tr>
        }
        

    </tbody>
  );
}